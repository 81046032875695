import React, { useContext } from "react"
import { Button, Icon, Segment } from 'semantic-ui-react'
import { useIntl, FormattedMessage } from "react-intl"
import { AppContext } from '../providers/AppContext'

import Layout from "../components/layout"
import SEO from "../components/seo"
import './contact-form-submitted.css'

const ContactFormSubmittedPageInternal = () => {
  const intl = useIntl()
  const appContext = useContext(AppContext)


  return (<>
    <SEO 
      title={intl.formatMessage({id: 'contactFormSubmitted.title'})}
      keywords={[
        `nvotes`, `secure`, `online`, `voting`, `tools`, `software`, `system`, 
        `election`, `poll`
      ]} 
    />

    <article className="slim contact-form-submitted">
      <div className="ui centered stackable grid container">
        <h1>
          <FormattedMessage id="contactFormSubmitted.thanks" />
        </h1>

        <div className="two column row">
          <div className="column">
            <Segment vertical textAlign='center' style={{fontFamily: 'Open Sans, sans-serif', color: '#404040'}}>
              <p>
                <FormattedMessage id="contactFormSubmitted.getFreeTrial" />
              </p>
            </Segment>
          </div>
          <div className="column">
            <Segment vertical textAlign='center' style={{fontFamily: 'Open Sans, sans-serif', color: '#404040'}}>
              <p>
                <FormattedMessage id="contactFormSubmitted.ifUrgent" />
              </p>
            </Segment>
          </div>
        </div>

        <div className="two column row">
          <div className="column">
            <Segment vertical textAlign='center' style={{fontFamily: 'Open Sans, sans-serif', color: '#404040'}}>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLScupP94F5cJVxzD8-Ysefdhyp-bmU5SC9eENRB9eLarfarxNw/viewform?c=0&w=1">
                <Button animated="fade" basic color="blue" size="huge">
                  <Button.Content visible>
                    <FormattedMessage id="contactFormSubmitted.electionDetailsForm" />
                  </Button.Content>
                  <Button.Content hidden>
                    <FormattedMessage id="contactFormSubmitted.electionDetailsForm" /> &nbsp;
                    <Icon name='arrow right' />
                  </Button.Content>
                </Button>
              </a>
              <br/><br/><br/>
              <p>
                <FormattedMessage 
                  id="contactFormSubmitted.callUs"
                  values={{
                    a: linkText => (
                      <a 
                        href={
                          appContext.locale === 'es'
                            ? 'https://calendly.com/nvotes/llamada'
                            : 'https://calendly.com/nvotes/call'
                        } 
                        target="_blank" 
                        rel="noopener noreferrer">
                        {linkText}
                      </a>
                    ),
                    br: _ => <br />
                  }}
                  />
              </p>
            </Segment>
          </div>
        </div>
      </div>
    </article>
  </>)
}

const ContactFormSubmittedPage = () => (
  <Layout>
    <ContactFormSubmittedPageInternal />
  </Layout>
)

export default ContactFormSubmittedPage

